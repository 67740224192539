import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ContactSection from "./ContactSection"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        home {
          contact {
            name
            city
            state
            phone
            email
          }
        }
      }
    `}
    render={data => <ContactSection data={data} {...props} />}
  />
)
