import React from 'react'
import Layout from '../components/layout'
import { graphql } from "gatsby"

import { withStyles } from '@material-ui/core/styles';
import ContactSection from '../components/ContactSectionQuery'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  contactWrapper: {
    backgroundColor: "black",
  },
});

class ContactPage extends React.Component {
  render() {
    // store up the array of images fluid value from imageSharp
    const { data, classes } = this.props
    const contactData = data.home
    // console.log('ContactPage data', data)
    return (
      <Layout
        className={classes.root}
        location={this.props.location}
        pagedata={{ title: contactData.title, description: contactData.description }}
      >
      <div className={classes.contactWrapper}><ContactSection /></div>
      </Layout>
    )
  }
}

export default withStyles(styles)(ContactPage);

export const contactInfo = graphql`
query SiteContactQuery {
    home {
      description
      title
    }
  }
`
