import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper';
import DartHeading from './icons/DartHeading'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 16, // Half the value of the grid spacing
    backgroundColor: theme.palette.hero.main,
    minHeight: 500
  },
  bbg: {
    border: `solid 2px ${theme.palette.primary.dark}`,
    borderRadius: theme.spacing.unit*2,
    backgroundColor: theme.palette.common.black,
  },
  contactWrapper: {
    border: `solid 4px ${theme.palette.primary.dark}`,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.hero.light,
    color: theme.palette.primary.dark,
  },
  contactItem: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
  },
  iconText: {
    marginBottom: 24,
    width: 400,
    "& #line": {
      fill: "transparent",
    },
    "& #mainText": {
      "alignment-baseline": "middle",
      fontFamily: theme.typography.fontFamilySecond,
      fill: theme.palette.primary.light,
      fontSize: 64,
      fontWeight: 700,
      "text-anchor": "middle",
    },
    "& #dart": {
      fill: theme.palette.common.black,
    },
    "& #dart_2": {
      fill: theme.palette.primary.dark,
    }
  }
});

class ContactSection extends React.Component {

  render() {
    const { classes, data } = this.props
    // const { open } = this.state;
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={16}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={16}>
              <DartHeading viewBox="0 250 800 300" className={classes.iconText} iconText="Contact" />
            </Grid>
          </Grid>
          <Grid className={classes.bbg} item xs={12} sm={12} lg={6}>
            <Paper className={classes.contactWrapper} elevation={4}>
              <Typography className={classes.contactItem} color="inherit" variant="h6">{data.home.contact.email}</Typography>
              <Typography className={classes.contactItem} color="inherit" variant="h6">{data.home.contact.phone}</Typography>
              <Typography className={classes.contactItem} color="inherit" variant="h5">{data.home.contact.city}, {data.home.contact.state}</Typography>
            </Paper>
          </Grid>

        </Grid>
      </div>
    )
  }
}

ContactSection.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ContactSection)
